import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {

  ajudaForm!: FormGroup;
  telefoneMask = '(00)00000-0000';
  cookies = localStorage.getItem('Cookies');
  politicaPrivacidade = localStorage.getItem('PoliticaPrivacidade');
  menuOpen = false;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.criaForm();
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  closeMenu() {
    this.menuOpen = false;
  }

  acceptCookies(): any {
    localStorage.setItem('Cookies', 'accept');
    localStorage.setItem('PoliticaPrivacidade', 'accept');
    this.politicaPrivacidade = 'accept';
    this.cookies = 'accept';
  }

  scroll(el: HTMLElement): void {
    el.scrollIntoView();
  }

  simularOutros(tipo: string): void {
    const texto = `Olá fui redirecionado pelo site para dar continuidade na solicitação de empréstimo!\n\n*Tipo de Simulação*: ${tipo}`;
    const textoCodificado = window.encodeURIComponent(texto);
    window.open(`https://wa.me/5518981410039?text=${textoCodificado}`);
  }

  enviarMensagem(): void {
    const texto = `Olá fui redirecionado pelo site!\n\n*Nome*: ${this.ajudaForm.get('nome')?.value}\n*Telefone*: https://wa.me/${this.ajudaForm.get('telefone')?.value}\n*Titulo*: ${this.ajudaForm.get('titulo')?.value}\n*Mensagem*: ${this.ajudaForm.get('mensagem')?.value}`;
    const textoCodificado = window.encodeURIComponent(texto);
    window.open(`https://wa.me/5518981410039?text=${textoCodificado}`);
    this.limparForm();
  }

  apiWhats(): void {
    // eslint-disable-next-line max-len
    window.open(`https://wa.me/5518981410039?text=Olá fui redirecionado pelo site para mais informações. Você pode me Ajudar?`);
  }

  apiLinkedin(): void {
    window.open(`https://www.linkedin.com/`);
  }

  apiInsta(): void {
    window.open(`https://www.instagram.com/consiga_cred_birigui/`);
  }

  apiFace(): void {
    window.open(`https://www.facebook.com/consigacred.birigui`);
  }

  private limparForm(): void {
    this.ajudaForm.reset();
  }

  private criaForm(): void {
    if (!this.ajudaForm) {
      this.ajudaForm = this.fb.group({
        nome: this.fb.control('', [Validators.required]),
        telefone: this.fb.control('', [Validators.required]),
        titulo: this.fb.control('', [Validators.required]),
        mensagem: this.fb.control('', [Validators.required])
      });
    }
  }
}
