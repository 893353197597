import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termos-de-uso',
  templateUrl: './termos-de-uso.component.html',
  styleUrls: ['./termos-de-uso.component.scss']
})
export class TermosDeUsoComponent implements OnInit {

  cookies = localStorage.getItem('Cookies');
  politicaPrivacidade = localStorage.getItem('PoliticaPrivacidade');

  constructor() { }

  ngOnInit(): void {
  }

  acceptCookies(): any {
    localStorage.setItem('Cookies', 'accept');
    localStorage.setItem('PoliticaPrivacidade', 'accept');
    this.politicaPrivacidade = 'accept';
    this.cookies = 'accept';
  }

  apiWhats(): void {
    // eslint-disable-next-line max-len
    window.open(`https://wa.me/5518981410039?text=Olá fui redirecionado pelo site para mais informações. Você pode me Ajudar?`);
  }

  apiLinkedin(): void {
    window.open(`https://www.linkedin.com/`);
  }

  apiInsta(): void {
    window.open(`https://www.instagram.com/consiga_cred_birigui/`);
  }

  apiFace(): void {
    window.open(`https://www.facebook.com/consigacred.birigui`);
  }

}
