import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { TermosDeUsoComponent } from './termos-de-uso/termos-de-uso.component';

import { SiteComponent } from './site/site.component';
import { Routes } from '@angular/router';


export const ROUTES: Routes = [
  // { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '', component: SiteComponent },
  { path: 'termos-de-uso', component: TermosDeUsoComponent },
  { path: 'politica-de-privacidade', component: PoliticaPrivacidadeComponent }
];
